import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from "./CustomEventHandlerService";
import FileUploadModel from "../Components/File/FileUploadModel";
import {
  FMVRateExceptionDTO,
  FMVFileUploadDTO,
  FileDTO,
  ExceptionManagementDTO,
  FileMetaDataDTO,
  FMVFileDTO,
  EmployeeDTO,
  EmailDTO,
  RateExceptionAuditDTO,
  RateExceptionCommentsDTO,
} from "../Models";
import { CustomEventType, DocumentType } from "../Common/Enums";
import S3FileService from "./S3FileService";
import Utils from "../Common/Utils";
import EmeraldService from "./EmeraldService";

class FMVRateExceptionService extends ProQuestAPIService {
  async getAll(): Promise<ExceptionManagementDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
    const response = await this.GetAsync<ExceptionManagementDTO[]>(
      "exceptionrequest/getall"
    );

    let rateExceptionData: ExceptionManagementDTO[] = [];

    if (response.isSuccess) {
      rateExceptionData = response.data;

      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    return rateExceptionData;
  }
  async getEmployee(searchText: string | null): Promise<EmployeeDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    let lillyEmployeeList: EmployeeDTO[] = await EmeraldService.searchByName(searchText ?? '');

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    return lillyEmployeeList;
  }

  async getByRequestId(
    requestid: number | string
  ): Promise<ExceptionManagementDTO | null> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
    const response = await this.GetAsync<ExceptionManagementDTO>(
      `exceptionrequest/getbyrequestid/${requestid}`
    );

    if (response.isSuccess) {
      const requestData = response.data;

      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

      return requestData;
    }
    return null;
  }

  async getByFacultyId(
    facultyid: number | string
  ): Promise<ExceptionManagementDTO[]> {
    const response = await this.GetAsync<ExceptionManagementDTO[]>(
      `exceptionrequest/getbyfacultyid/${facultyid}`
    );
    let apiResponse: ExceptionManagementDTO[] = [];
    if (response.isSuccess) {
      apiResponse = response.data;
    }

    return apiResponse;
  }

  async getByRequesterEmail(
    requesteremail: string
  ): Promise<ExceptionManagementDTO[]> {
    const response = await this.GetAsync<ExceptionManagementDTO[]>(
      `exceptionrequest/getbyrequesteremail/${requesteremail}`
    );
    let apiResponse: ExceptionManagementDTO[] = [];
    if (response.isSuccess) {
      apiResponse = response.data;
    }

    return apiResponse;
  }

  async getDashboardData(): Promise<ExceptionManagementDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
    const response = await this.GetAsync<ExceptionManagementDTO[]>(
      "fmvdashboard/getalldashboarddata"
    );

    let apiResponse: ExceptionManagementDTO[] = [];

    if (response.isSuccess) {
      apiResponse = response.data;

      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    return apiResponse;
  }

  async add(
    exception: FMVRateExceptionDTO | null
  ): Promise<FMVRateExceptionDTO | null> {
    const response = await this.PostAsync<FMVRateExceptionDTO>(
      "exceptionrequest/create",
      exception
    );
    //console.log(response)
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(
        CustomEventType.SuccessAlert,
        response.message
      );
      return response.data;
    } else {
      let message = response.message;

      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
      return null;
    }
  }

  async update(
    exception: ExceptionManagementDTO,
    requestid: number | string
  ): Promise<ExceptionManagementDTO | null> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const response = await this.PutAsync<ExceptionManagementDTO>(
      `exceptionrequest/update/${requestid}`,
      exception
    );

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(
        CustomEventType.SuccessAlert,
        response.message
      );
      return response.data;
    } else {
      CustomEventHandlerService.dispatch(
        CustomEventType.ErrorAlert,
        response.message
      );
      return null;
    }
  }
  async getMetaData(key: string): Promise<FileMetaDataDTO | null> {
    const fileObj = {
      key,
    };

    const response = await this.PostAsync<FileMetaDataDTO>(
      "s3metadata",
      fileObj
    );

    if (response.isSuccess) {
      return response.data;
    }

    return null;
  }

  async getAllFile(
    request_id: string,
    documentType: DocumentType
  ): Promise<FMVFileDTO[]> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const response = await this.GetAsync<FMVFileDTO[]>(
      `exceptionrequestfile/getall/${request_id}`
    );

    if (response.isSuccess) {
      let exceptionFiles = response.data;

      exceptionFiles = await Promise.all(
        exceptionFiles
          .filter((file) => file.documenttype === documentType)
          .map(async (exceptionFile) => {
            const metaData = await this.getMetaData(exceptionFile.s3filekey);

            if (metaData != null) {
              exceptionFile.fileSizeInKB = Math.round(
                metaData.ContentLength / 1024
              );
            }

            return exceptionFile;
          })
      );

      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

      return exceptionFiles;
    }

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    return [];
  }
  async getFile(key: string, fileType: string): Promise<string | null> {
    const fileObj = {
      key,
      fileType,
    };

    const filePreSignedResponse = await this.PostAsync<FileDTO>(
      "s3download",
      fileObj
    );

    if (filePreSignedResponse.isSuccess) {
      return filePreSignedResponse.data.presigned_url;
    }

    return null;
  }

  async deleteFile(
    file: FileUploadModel,
    documentType: DocumentType
  ): Promise<boolean> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const fileObj = {
      key: `${file.facultyId}/${documentType}/${file.name}`,
      fileType: file.type,
    };

    const filePreSignedResponse = await this.PostAsync<FileDTO>(
      "s3delete",
      fileObj
    );

    if (filePreSignedResponse.isSuccess) {
      try {
        const { presigned_url } = filePreSignedResponse.data;

        const isFileDeleteSuccess = await S3FileService.deleteAsync(
          presigned_url
        );

        if (isFileDeleteSuccess) {
          const exceptionFileResponse = await this.DeleteAsync<null>(
            `exceptionrequestfile/delete/${file.id}`
          );

          if (exceptionFileResponse.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            return true;
          } else {
            console.log("exceptionFileResponse", exceptionFileResponse);
          }
        }
      } catch (error) {
        console.log("upload-error", error);
      }
    }

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    return false;
  }

  async uploadFile(
    file: FileUploadModel,
    documentType: DocumentType,
    request_id: string | undefined,
    customer_id: string,
    facultyId: string | number
  ): Promise<FMVFileUploadDTO | null> {
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
    console.log(file);
    const fileNameWithExt = file.name;

    const fileObj = {
      key: `${facultyId}/${request_id}/${documentType}/${fileNameWithExt}`,
      fileType: file.type,
    };

    const filePreSignedResponse = await this.PostAsync<FileDTO>(
      "s3upload",
      fileObj
    );

    if (filePreSignedResponse.isSuccess) {
      // put with presigned URL
      try {
        if (file.file) {
          const { presigned_url } = filePreSignedResponse.data;

          const isFileUploadSuccess = await S3FileService.putAsync(
            presigned_url,
            file.type.toString(),
            file.file
          );

          if (isFileUploadSuccess) {
            // add entry in table
            let facultyFileDTO: FMVFileUploadDTO = {
              id: 0,
              requestid: request_id,
              customerid: customer_id,
              facultyid: file.facultyId,
              filename: fileNameWithExt,
              filetype: Utils.getFileTypeNumber(file.type),
              documenttype: documentType,
              s3filekey: fileObj.key,
            };

            const facultyFileResponse = await this.PostAsync<FMVFileUploadDTO>(
              "exceptionrequestfile/create",
              facultyFileDTO
            );

            if (facultyFileResponse.isSuccess) {
              facultyFileDTO = facultyFileResponse.data;

              CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
              return facultyFileDTO;
            } else {
              throw new Error(facultyFileResponse.message);
            }
          } else {
            throw new Error("Failed to upload file");
          }
        } else {
          throw new Error("No file to upload");
        }
      } catch (error) {
        console.log("upload-error", error);
      }
    } else {
      console.log("filePreSignedResponse", filePreSignedResponse);
    }

    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    return null;
  }

  async sendEmail(Email: EmailDTO): Promise<EmailDTO[]> {
    console.log("sendEmail", Email);
    CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

    const response = await this.PostAsync<EmailDTO[]>(
      "emailer/sendemail",
      Email
    );

    let email: EmailDTO[] = [];

    if (response.isSuccess) {
      email = response.data;
      CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    return email;
  }

  async getAllAudit(requestid: string): Promise<RateExceptionAuditDTO[]> {
    const response = await this.GetAsync<RateExceptionAuditDTO[]>(
      `exceptionrequest/getallaudits/${requestid}`
    );

    let audits: RateExceptionAuditDTO[] = [];

    if (response.isSuccess) {
      audits = response.data;
    }

    return audits;
  }

  async createAudit(
    exceptionAudit: RateExceptionAuditDTO | null
  ): Promise<RateExceptionAuditDTO | null> {
    const response = await this.PostAsync<RateExceptionAuditDTO>(
      "exceptionrequest/createaudit",
      exceptionAudit
    );
    //console.log(response)
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(
        CustomEventType.SuccessAlert,
        response.message
      );
      return response.data;
    } else {
      let message = response.message;

      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
      return null;
    }
  }
  
  async getAllCommentsLog(
    requestid: string
  ): Promise<RateExceptionCommentsDTO[]> {
    const response = await this.GetAsync<RateExceptionCommentsDTO[]>(
      `exceptionrequest/getallcomments/${requestid}`
    );

    let audits: RateExceptionCommentsDTO[] = [];

    if (response.isSuccess) {
      audits = response.data;
    }

    return audits;
  }

  async createCommentsLog(
    exceptionCommentsLog: RateExceptionCommentsDTO | null
  ): Promise<RateExceptionCommentsDTO | null> {
    const response = await this.PostAsync<RateExceptionCommentsDTO>(
      "exceptionrequest/createcomment",
      exceptionCommentsLog
    );
    //console.log(response)
    if (response.isSuccess) {
      CustomEventHandlerService.dispatch(
        CustomEventType.SuccessAlert,
        response.message
      );
      return response.data;
    } else {
      let message = response.message;

      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
      return null;
    }
  }
}

const fmvRateExceptionService = new FMVRateExceptionService();
export default fmvRateExceptionService;