import React from 'react';
import { IconButton, InputAdornment, WithStyles, withStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import TextInputField from '../TextInput/TextInputField';
import SearchFilterModel from './SearchFilterModel';

const styles = (theme: Theme) => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#AAAAAA !important',
    },
    root: {
        backgroundColor: '#F7F7F7',
    },
});

interface Props extends WithStyles<typeof styles> {
    label: string;
    value: string | number;
    useDelay: boolean;
    onChange: ((searchFilter: SearchFilterModel) => void);
}
interface State { }
class SearchFilter extends React.Component<Props, State> {
    public static defaultProps = {
        useDelay: true,
    };

    render() {
        const { label, value, useDelay, classes } = this.props;

        return (
            <TextInputField value={value} id="searchFilter" label={label} isRequired={false} useDelay={useDelay}
                classes={classes}
                endAdornment={(
                    <InputAdornment position="end">
                        <IconButton style={{ color: '#EE3A29' }}>
                            <FontAwesomeIcon icon={faSearch} size="xs" />
                        </IconButton>
                    </InputAdornment>
                )} onChange={this.handleChange} />
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const searchFilter = new SearchFilterModel();
        searchFilter.searchText = event.target.value;

        this.props.onChange(searchFilter);
    }
}

export default withStyles(styles)(SearchFilter);