import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { FacultyDTO, FacultyPPQDataDTO, QuestionnaireDTO, QuestionnaireManagementFacultyDTO, TabFacultyCountDTO } from "../../Models";
import { ADMINQuestionnaireService, CustomEventHandlerService, FacultyPPQDataService, HCPTypeService, SpecialtyService } from "../../Services";

import TabBar from "./TabBar";
import { TabModel } from "./TabModel";
import PageHeader from "../../Components/Text/PageHeader";
import FacultyFilterModel from "./FacultyFilterModel";
import SelectModel from "../../Components/Select/SelectModel";
import Utils from "../../Common/Utils";
import { CustomEventType } from "../../Common/Enums";
import FacultyService from "../../Services/FacultyService";

interface LocationState {
    facultyId: string;
}

interface Props extends RouteComponentProps<{}, any, LocationState> { }
interface State {
    currentTab: TabModel;
    hcpTypes: SelectModel[];
    specialties: SelectModel[];
    tabCount?: TabFacultyCountDTO;
    selectedFaculty?: FacultyDTO;
    selectedFacultyPPQData: FacultyPPQDataDTO | null;
    facultyFilter: FacultyFilterModel;
    tieredData: QuestionnaireManagementFacultyDTO;
    pendingTieringData: QuestionnaireManagementFacultyDTO;
    initiatePPQData: QuestionnaireManagementFacultyDTO;
    filterData: QuestionnaireManagementFacultyDTO;
    showQuestionnaire: boolean;
    questionnaire?: QuestionnaireDTO;
    totalPoints: number;
    page: number;
    pageSize: number;
    totalCount: number;
}
class QuestionnaireManagement extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'Tiered',
            type: 'Tiered',
            index: 0,
        },
        {
            name: 'Pending Tiering',
            type: 'PendingTiering',
            index: 1,
        },
        {
            name: 'Initiate PPQ',
            type: 'InitiatePPQ',
            index: 2,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            currentTab: this.Tabs[0],
            hcpTypes: [],
            specialties: [],
            tabCount: undefined,
            selectedFaculty: undefined,
            selectedFacultyPPQData: null,
            facultyFilter: new FacultyFilterModel(),
            tieredData: { faculties: [], facultyPPQData: [] },
            pendingTieringData: { faculties: [], facultyPPQData: [] },
            initiatePPQData: { faculties: [], facultyPPQData: [] },
            filterData: { faculties: [], facultyPPQData: [] },
            showQuestionnaire: false,
            totalPoints: 0,
            page: 1,
            pageSize: 10,
            totalCount: 0,
        };
    }

    async componentDidMount() {
        const { location } = this.props;
        const { page, pageSize } = this.state;
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        let currentTab: TabModel = this.Tabs[0];
        let selectedFacultyPPQData: FacultyPPQDataDTO | null = null;
        let selectedFaculty: FacultyDTO | undefined = undefined;
        let filterData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let tieredData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let pendingTieringData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };

        const specialties = await SpecialtyService.getAllForSelect();
        const hcpTypes = await HCPTypeService.getAllForSelect();
        const tabCount = await ADMINQuestionnaireService.getTabFacultyCount();

        if (location.state && location.state.facultyId) {
            pendingTieringData = await FacultyPPQDataService.getPendingTieringData();
            selectedFacultyPPQData = await FacultyPPQDataService.getByFacultyId(location.state.facultyId);
            currentTab = this.Tabs[1];
            selectedFaculty = pendingTieringData.faculties.find(p => p.facultyid === selectedFacultyPPQData?.facultyid);
            filterData = pendingTieringData;
        } else {
            tieredData = await FacultyPPQDataService.getTieredData(page, pageSize, '', '', null, '');
            filterData = tieredData;
        }

        this.setState({
            hcpTypes, specialties, tabCount,
            tieredData, pendingTieringData,
            selectedFacultyPPQData, selectedFaculty,
            currentTab, totalCount: filterData.count ?? 0,
        }, () => {
            filterData = this.getFilteredData(filterData);

            this.setState({ filterData }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        });
    }

    render() {
        const {
            currentTab,
            hcpTypes, specialties, tabCount,
            selectedFaculty, selectedFacultyPPQData, facultyFilter,
            filterData, showQuestionnaire, totalPoints,
            page, pageSize, totalCount,
        } = this.state;

        return (
            <div>
                <PageHeader label="Questionnaire Management" />
                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    hcpTypes={hcpTypes} specialties={specialties} tabCount={tabCount}
                    filterData={filterData}
                    selectedFaculty={selectedFaculty} selectedFacultyPPQData={selectedFacultyPPQData} facultyFilter={facultyFilter}
                    onFacultyChange={this.handleFacultyChange} onFacultyFilterChange={this.handleFacultyFilterChange}
                    onFacultyFilterReset={this.handleFacultyFilterReset} onSubmitSuccess={this.handleQuestionnaireSubmit}
                    showQuestionnaire={showQuestionnaire} onCVChange={this.handleCVChange}
                    totalPoints={totalPoints} onQuestionnaireChange={this.handleQuestionnaireChange}
                    page={page} pageSize={pageSize} totalCount={totalCount} onPageChange={this.handlePageChange}
                />
            </div>
        );
    }

    getFilteredData = (filterData: QuestionnaireManagementFacultyDTO): QuestionnaireManagementFacultyDTO => {
        const { currentTab } = this.state;
        const { faculties, facultyPPQData } = filterData;
        let sortedFaculties = faculties;

        switch (currentTab.type) {
            case "PendingTiering":
                sortedFaculties = sortedFaculties.sort((a, b) => {
                    if (a.updatedon === null && b.updatedon === null) {
                        return 0;
                    }

                    if (a.updatedon === undefined && b.updatedon === undefined) {
                        return 0;
                    }

                    if (!a.updatedon) {
                        return 1;
                    }

                    if (!b.updatedon) {
                        return -1;
                    }

                    return +new Date(b.updatedon) - +new Date(a.updatedon);
                });
                break;
            case "Tiered":
                sortedFaculties = sortedFaculties.sort((a, b) => {
                    const aFacultyPPQ = facultyPPQData.find(p => p.facultyid === a.facultyid);
                    const bFacultyPPQ = facultyPPQData.find(p => p.facultyid === b.facultyid);

                    if (aFacultyPPQ === null && bFacultyPPQ === null) {
                        return 0;
                    }

                    if (aFacultyPPQ === undefined && bFacultyPPQ === undefined) {
                        return 0;
                    }

                    if (!aFacultyPPQ) {
                        return 1;
                    }

                    if (!bFacultyPPQ) {
                        return -1;
                    }

                    return +new Date(bFacultyPPQ.ppqsubmissiondate) - +new Date(aFacultyPPQ.ppqsubmissiondate);
                });
                break;
            default:
                break;
        }

        return { facultyPPQData, faculties: sortedFaculties, count: filterData.count };
    }

    handleTabChange = async (selectedTab: TabModel) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        const tabCount = await ADMINQuestionnaireService.getTabFacultyCount();
        let filterData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let tieredData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let pendingTieringData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let initiatePPQData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };

        switch (selectedTab.type) {
            case "Tiered":
                tieredData = await FacultyPPQDataService.getTieredData(1, 10, '', '', null, '');
                filterData = tieredData;
                break;
            case "PendingTiering":
                pendingTieringData = await FacultyPPQDataService.getPendingTieringData();
                filterData = pendingTieringData;
                break;
            case "InitiatePPQ":
                let facultiestoInitiate = await FacultyService.getAll();

                facultiestoInitiate = facultiestoInitiate.filter(p => p.questionairystatus === null);
                initiatePPQData = { faculties: facultiestoInitiate, facultyPPQData: [] };
                filterData = initiatePPQData;
                break;
            default:
                break;
        }

        this.setState({
            currentTab: selectedTab, tabCount,
            selectedFaculty: undefined, selectedFacultyPPQData: null,
            tieredData, pendingTieringData, initiatePPQData, filterData,
            facultyFilter: new FacultyFilterModel(),
            page: 1, pageSize: 10, totalCount: tieredData.count ?? 0
        }, () => {
            filterData = this.getFilteredData(filterData);
            this.setState({ filterData }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        });
    }

    handleFacultyChange = async (selectedFaculty: FacultyDTO) => {
        const selectedFacultyPPQData = await FacultyPPQDataService.getByFacultyId(selectedFaculty.facultyid);

        this.setState({ selectedFaculty, selectedFacultyPPQData }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.ClearAlert);
        });
    }

    handleFacultyFilterReset = async () => {
        const { pendingTieringData, currentTab } = this.state;

        if (currentTab.type === 'Tiered') {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
            let filterData = await FacultyPPQDataService.getTieredData(1, 10, '', '', null, '');
            filterData = this.getFilteredData(filterData);
            this.setState({
                facultyFilter: new FacultyFilterModel(), filterData,
                page: 1, pageSize: 10, totalCount: filterData.count ?? 0
            }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        } else {
            const filterData = this.getFilteredData(pendingTieringData);
            this.setState({ facultyFilter: new FacultyFilterModel(), filterData });
        }
    }

    handleFacultyFilterChange = (id: string, value: any) => {
        const { facultyFilter, pageSize } = this.state;

        let facultyFilterData: any = Object.assign({}, facultyFilter);
        facultyFilterData[id] = value;

        this.setState({ facultyFilter: facultyFilterData, page: 1 }, async () => {
            const { facultyFilter, tieredData, pendingTieringData, initiatePPQData, currentTab } = this.state;
            const { searchText, hcptype, specialty, createdon } = facultyFilter;

            if (currentTab.type === 'Tiered') {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
                let filterData = await FacultyPPQDataService.getTieredData(
                    1, pageSize, hcptype, specialty, createdon, searchText);
                filterData = this.getFilteredData(filterData);

                this.setState({ filterData, totalCount: filterData.count ?? 0 }, () => {
                    CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
                });
            } else {
                let sourceData = tieredData;

                if (currentTab.type === 'PendingTiering') {
                    sourceData = pendingTieringData;
                } else if (currentTab.type === 'InitiatePPQ') {
                    sourceData = initiatePPQData;
                }
                const { faculties, facultyPPQData } = sourceData;

                let filterFaculties = faculties.filter(faculty => {
                    const conditions: boolean[] = [];
                    const facultyPPQ = facultyPPQData.find(p => p.facultyid === faculty.facultyid);

                    if (searchText) {
                        const uppercaseValue = searchText.toUpperCase();
                        const searchCondition = faculty.firstname.toUpperCase().includes(uppercaseValue)
                            || faculty.lastname.toUpperCase().includes(uppercaseValue)
                            || faculty.facultyid.toString().toUpperCase().includes(uppercaseValue)
                            || (faculty.firstname.toUpperCase() + ' ' + faculty.lastname.toUpperCase()).includes(uppercaseValue);
                        conditions.push(searchCondition);
                    }

                    if (createdon && facultyPPQ) {
                        const dateCondition = facultyPPQ.ppqsubmissiondate !== null ?
                            Utils.isEqualDates(new Date(facultyPPQ.ppqsubmissiondate), createdon) : false;
                        conditions.push(dateCondition);
                    }

                    if (hcptype) {
                        const hcpTypeCondition = faculty.hcptype !== null ?
                            faculty.hcptype.includes(hcptype) : false;
                        conditions.push(hcpTypeCondition);
                    }

                    if (specialty) {
                        const specialtyCondition = faculty.specialty.includes(specialty);
                        conditions.push(specialtyCondition);
                    }

                    let finalCondition: boolean | null = null;

                    conditions.forEach(condition => {
                        if (finalCondition === null) {
                            finalCondition = condition;
                        } else {
                            finalCondition = finalCondition && condition;
                        }
                    });

                    return finalCondition === null ? false : finalCondition;
                });

                if (!(searchText || hcptype || specialty || createdon)) {
                    filterFaculties = sourceData.faculties;
                }

                let filterData: QuestionnaireManagementFacultyDTO = { faculties: filterFaculties, facultyPPQData };
                filterData = this.getFilteredData(filterData);
                this.setState({ filterData });
            }
        });
    };

    handleQuestionnaireSubmit = async (selectedOldFaculty: FacultyDTO, message: string) => {
        const { currentTab, page, pageSize } = this.state;

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        const tabCount = await ADMINQuestionnaireService.getTabFacultyCount();
        let filterData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let tieredData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let pendingTieringData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };
        let initiatePPQData: QuestionnaireManagementFacultyDTO = { faculties: [], facultyPPQData: [] };

        switch (currentTab.type) {
            case "Tiered":
                tieredData = await FacultyPPQDataService.getTieredData(page, pageSize, '', '', null, '');
                filterData = tieredData;
                break;
            case "PendingTiering":
                pendingTieringData = await FacultyPPQDataService.getPendingTieringData();
                filterData = pendingTieringData;
                break;
            case "InitiatePPQ":
                let facultiestoInitiate = await FacultyService.getAll();

                facultiestoInitiate = facultiestoInitiate.filter(p => p.questionairystatus === null);
                initiatePPQData = { faculties: facultiestoInitiate, facultyPPQData: [] };
                filterData = initiatePPQData;
                break;
            default:
                break;
        }

        filterData = this.getFilteredData(filterData);
        this.setState({
            tabCount,
            tieredData, pendingTieringData, initiatePPQData, filterData,
        }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    handleCVChange = (isValid: boolean) => {
        this.setState({ showQuestionnaire: isValid });
    }

    handleQuestionnaireChange = (questionnaire: QuestionnaireDTO) => {
        const { facultyQuests } = questionnaire;
        const totalPoints = facultyQuests.reduce((point, facultyQuest) => point + facultyQuest.points, 0);

        this.setState({ questionnaire, totalPoints });
    }

    handlePageChange = (page: number) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        this.setState({ page }, async () => {
            const { pageSize, facultyFilter } = this.state;
            const { searchText, hcptype, specialty, createdon } = facultyFilter;
            const filterData = await FacultyPPQDataService.getTieredData(page, pageSize, hcptype, specialty, createdon, searchText);
            this.setState({ filterData, totalCount: filterData.count ?? 0 }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        });
    }
}

export default QuestionnaireManagement;